@import "@folio/stripes-components/lib/variables.css";

@media (--large-up) {
  .versionsContainer {
    display: flex;
  }

  .versionsColumn {
    flex: 1;
    padding: 0 1em;
  }
}

.isEmptyMessage {
  color: var(--sub-label-color);
}

.absent {
  color: red;
  font-weight: bold;
}

.incompatible {
  color: orange;
}

.paddingLeftOfListItems {
  padding-left: 14px;
}
