@import "@folio/stripes-components/lib/variables";

.panePlaceholder {
  background-color: var(--color-fill);
  flex: 1;
}

.appIcon {
  margin-top: -1px;
}
